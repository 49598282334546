import * as React from "react"
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Card from '../components/Card';
import Pagination from '../components/Pagination';
import createSlug from '../utils/createSlug';
import Seo from '../components/Seo';

function PostPage({ data, pageContext }) {

  const post = data;
  const { previous, next } = pageContext;

  return (
    <Layout>
      <Seo title={post.markdownRemark.frontmatter.title} />
      <Card
        key={`card-${post.markdownRemark.id}`}
        title={post.markdownRemark.frontmatter.title}
        featureImage={post.markdownRemark.frontmatter.featuredImage?.childImageSharp.gatsbyImageData.images.fallback}
        date={new Date(post.markdownRemark.frontmatter.date)}
        content={post.markdownRemark.html}
        categories={post.markdownRemark.frontmatter.categories?.map(a => {
          return {
            title: a,
            slug: createSlug(a)
          }
        })}
        tags={post.markdownRemark.frontmatter.tags?.map(a => {
          return {
            title: a,
            slug: createSlug(a)
          }
        })}
      />

      <Pagination isFirst={!previous} isLast={!next} previousUrl={previous?.fields.slug} nextUrl={next?.fields.slug} previousText={previous?.frontmatter.title} nextText={next?.frontmatter.title} />

    </Layout>
  );
}

export default PostPage;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        categories
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 850, placeholder: BLURRED, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;