import React from 'react';
import TimeAgo from 'timeago-react';
import GatsbyLink from 'gatsby-link';

interface Tag {
  title: string;
  slug: string;
}

interface Category {
  title: string;
  slug: string;
}

interface FeatureImage {
  src: string;
  srcSet: string;
  sizes: string;
}

interface CardProps {
  title: string;
  featureImage: FeatureImage;
  slug: string;
  content: string;
  category: string;
  date: Date;
  tags: Tag[];
  categories: Category[];
}

function Card({ title, featureImage, slug, content, categories, date, tags }: CardProps) {

  return (
    <div className="mb-6">
      <div className="bg-white rounded-lg shadow-md">

        {featureImage && <img className="rounded-t-lg" src={featureImage.src} srcSet={featureImage.srcSet} />}

        <div className="p-8">

          {slug && <GatsbyLink to={slug} className="text-xl text-gray-700 font-bold hover:underline">
            {title}
          </GatsbyLink>}

          {!slug && <h1 className="text-xl text-gray-700 font-bold">{title}</h1>}

          <div className="flex justify-between items-center">
            <span className="font-light text-gray-600">
              {date?.toDateString()}

              {categories && categories.map(category =>
                <span key={`card-category-${category.slug}`} >
                  {" • "}
                  <GatsbyLink to={`/category/${category.slug}`} className="">
                    {category.title}
                  </GatsbyLink>
                </span>)
              }
            </span>
          </div>

          <div className="mt-4 text-gray-600" dangerouslySetInnerHTML={{ __html: content }}>
          </div>

          {slug &&
            <div className="flex justify-between items-center mt-4">
              <GatsbyLink to={slug} className="text-blue-500 hover:underline">
                Read more...
              </GatsbyLink>
            </div>
          }

          {tags &&
            <div className="mt-4 space-x-1">
              {tags?.map(tag =>
                <GatsbyLink key={`card-tag-${tag.slug}`} to={`/tags/${tag.slug}`} className="text-xs px-2 py-1 bg-blue-300 text-white rounded hover:bg-blue-500">
                  {tag.title}
                </GatsbyLink>
              )}
            </div>
          }
        </div>

      </div>
    </div>
  );
}

export default Card;

function Card2({ title, content, categories, date, tags }: CardProps) {

  return (
    <div className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden mx-auto">

      {/* Header */}
      <div className="flex flex-row mt-2 px-2 py-3 mx-3">
        <div className="w-auto h-auto rounded-full border-2 border-blue-400">
          <img className="w-12 h-12 object-cover rounded-full shadow cursor-pointer" alt="User avatar" src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=200" />
        </div>
        <div className="flex flex-col mb-2 ml-4 mt-1">
          <div className="text-gray-600 text-sm font-semibold">Terry Butler</div>
          <div className="flex w-full mt-1">
            <div className="text-blue-700 font-base text-xs mr-1 cursor-pointer">
              {categories?.map(a => <a key={`card-category-${a.slug}`} href="#">{a.title}</a>)}
            </div>
            <div className="text-gray-400 font-thin text-xs">
              • <TimeAgo datetime={date} />
            </div>
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="flex-grow">
        <div className="border-b border-gray-100"></div>
        <div className="text-gray-600 font-semibold text-lg m-3">
          {title}
        </div>
        <div className="text-gray-400 font-medium text-sm m-3">
          <img className="rounded" src="https://picsum.photos/800/600" />
        </div>
        <div className="text-gray-500 font-thin text-sm mb-6 mx-3">
          {content}
        </div>
      </div>

      {/* Footer */}
      <div className="flex justify-start mb-4 border-t border-gray-100">
        <div className="flex w-full mt-1 pt-2 pl-5">
          <div className="text-gray-400 font-thin text-xs">
            <div className="flex space-x-1">
              <span className="p-1">Tags:</span>

              {tags &&
                <>
                  {tags.map(a => <span key={`card-tag-${a.slug}`} className="bg-blue-100 text-black rounded-md items-center justify-center p-1 ">
                    <GatsbyLink to={`/tags/${a.slug}`} >
                      {a.title}
                    </GatsbyLink>
                  </span>)}
                </>
              }
            </div>
          </div>
        </div>

        {/* <div className="flex w-full mt-1 pt-2 pl-5">
              <span className="bg-white transition ease-out duration-300 hover:text-red-500 border w-8 h-8 px-2 pt-2 text-center rounded-full text-gray-400 cursor-pointer mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="14px" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                </svg>
              </span>
              <img className="inline-block object-cover w-8 h-8 text-white border-2 border-white rounded-full shadow-sm cursor-pointer" src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              <img className="inline-block object-cover w-8 h-8 -ml-2 text-white border-2 border-white rounded-full shadow-sm cursor-pointer" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              <img className="inline-block object-cover w-8 h-8 -ml-2 text-white border-2 border-white rounded-full shadow-sm cursor-pointer" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80" alt="" />
              <img className="inline-block object-cover w-8 h-8 -ml-2 text-white border-2 border-white rounded-full shadow-sm cursor-pointer" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80" alt="" />
            </div>
            <div className="flex justify-end w-full mt-1 pt-2 pr-5">
              <span className="transition ease-out duration-300 hover:bg-blue-50 bg-blue-100 h-8 px-2 py-2 text-center rounded-full text-blue-400 cursor-pointer mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="14px" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                </svg>
              </span>
              <span className="transition ease-out duration-300 hover:bg-blue-500 bg-blue-600 h-8 px-2 py-2 text-center rounded-full text-gray-100 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="14px" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
              </span>
            </div> */}

      </div>
      {/*
            <div className="flex w-full border-t border-gray-100">
              <div className="mt-3 mx-5 flex flex-row">
                <div className="flex text-gray-700 font-normal text-sm rounded-md mb-2 mr-4 items-center">Comments:<div className="ml-1 text-gray-400 font-thin text-ms"> 30</div></div>
                <div className="flex text-gray-700 font-normal text-sm rounded-md mb-2 mr-4 items-center">Views: <div className="ml-1 text-gray-400 font-thin text-ms"> 60k</div></div>
              </div>
              <div className="mt-3 mx-5 w-full flex justify-end">
                <div className="flex text-gray-700 font-normal text-sm rounded-md mb-2 mr-4 items-center">Likes: <div className="ml-1 text-gray-400 font-thin text-ms"> 120k</div></div>
              </div>
            </div>
            */}
      {/*
            <div className="relative flex items-center self-center w-full max-w-xl p-4 overflow-hidden text-gray-600 focus-within:text-gray-400">
              <img className="w-10 h-10 object-cover rounded-full shadow mr-2 cursor-pointer" alt="User avatar" src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=200" />
              <span className="absolute inset-y-0 right-0 flex items-center pr-6">
                <button type="submit" className="p-1 focus:outline-none focus:shadow-none hover:text-blue-500">
                  <svg className="w-6 h-6 transition ease-out duration-300 hover:text-blue-500 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </button>
              </span>
              <input type="search" className="w-full py-2 pl-4 pr-10 text-sm bg-gray-100 border border-transparent appearance-none rounded-tg placeholder-gray-400 focus:bg-white focus:outline-none focus:border-blue-500 focus:text-gray-900 focus:shadow-outline-blue" style={{ borderRadius: "25px" }} placeholder="Post a comment..." />
            </div>
            */}
    </div>
  );
}
