import React from 'react';
import GatsbyLink from 'gatsby-link';

interface PaginationProps {
    isFirst: boolean;
    isLast: boolean;
    previousUrl: string;
    nextUrl: string;
    previousText: string;
    nextText: string;
}

function Pagination({ isFirst, isLast, previousUrl, nextUrl, previousText, nextText }: PaginationProps) {

    if (!previousUrl && !nextUrl) {
        return null;
    }

    return (
        <div className="w-full">

            {/* {JSON.stringify({ isFirst, isLast, previousUrl, nextUrl, previousText, nextText }, null, 2)} */}

            <div className="sm:grid grid-cols-4 gap-5 ">
                <div className="col-start-1 col-end-3">
                    {!isFirst && <GatsbyLink to={previousUrl}>
                        <div className="h-full p-6 dark:bg-gray-800 bg-white hover:shadow-xl rounded border-b-4 border-blue-300 shadow-md">
                            <h3 className="text-2xl mb-3 font-semibold inline-flex">
                                &larr; Previous
                            </h3>
                            {previousText && <p className="text-lg">{previousText}</p>}
                        </div>
                    </GatsbyLink>}
                </div>
                <div className="col-end-5 col-span-2">
                    {!isLast && <GatsbyLink to={nextUrl}>
                        <div className="h-full p-6 dark:bg-gray-800 bg-white hover:shadow-xl rounded border-b-4 border-blue-300 shadow-md text-right">
                            <h3 className="text-2xl mb-3 font-semibold inline-flex ">
                                Next &rarr;
                            </h3>
                            {nextText && <p className="text-lg">{nextText}</p>}
                        </div>
                    </GatsbyLink>}
                </div>
            </div>
        </div>
    );
}

export default Pagination;